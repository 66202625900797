import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Loader Component
const Loader = () => (
  <div className="flex flex-col items-center justify-center min-h-screen">
    <div className="mt-4 text-center">
      <p className="text-xl animate-pulse font-extrabold text-primary">
        Loading...
      </p>
      <p className="text-sm font-semibold text-primary/80">
        We are preparing your content.
      </p>
    </div>
  </div>
);

// Lazy-loaded components
const LoginPage = lazy(() => import('src/Pages/view/Auth'));
const Createnewpassword = lazy(() => import('src/Pages/view/Auth/createnewpassword'));
const SidebarAndProtected = lazy(() => import('./sidebarAndProjected'));
const WorkforceDemandDetail = lazy(() => import('src/Pages/app/Workforce/DemamdDetails'));
const WorkforceEmployeeDetails = lazy(() => import('src/Pages/app/Workforce/employeeDetails'));
const WorkforceDemandList = lazy(() => import('src/Pages/app/Workforce/demand'));
const WorkforceTalent = lazy(() => import('src/Pages/app/Workforce/talent'));
const WorkforceEprofile = lazy(() => import('src/Pages/app/Workforce/eprofile'));
const ProjectPlanning = lazy(() => import('src/Pages/app/Projects/planning'));
const ProjectStaffing = lazy(() => import('src/Pages/app/Projects/staffing'));
const ProjectTimesheet = lazy(() => import('src/Pages/app/Projects/timesheetUi'));
const AppFinancials = lazy(() => import('src/Pages/app/Financials'));
const ProjectListing = lazy(() => import('src/Pages/app/Projects/listing'));
const TenantLayout = lazy(() => import('src/Pages/app/Subscriptions'));
const AdminApp = lazy(() => import('src/Pages/app/Admin'));
const Analytics = lazy(() => import('src/Pages/app/Analytics'));
const WorkforceAnalytics = lazy(() => import('src/Pages/app/Analytics/workforceAnalytics'));
const AnalyticsForFinance = lazy(() => import('src/Pages/app/Analytics/financeAnalytics'));
const ReportsApp = lazy(() => import('src/Pages/app/Reports/capacity-workforce'));
const TimesheetReportsApp = lazy(() => import('src/Pages/app/Reports/project'));
const FinanceReportsApp = lazy(() => import('src/Pages/app/Reports/finance'));
const ProjectDetails = lazy(() => import('src/Pages/app/Projects/details'));
const Homedashboard = lazy(() => import('src/Pages/app/Home'));
const AccessDenied = lazy(() => import('src/Pages/app/AccessDenied'));
const NotFound = lazy(() => import('src/Pages/view/NotFound'));

const AllRoutes = () => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_TENANT_CODE} - Wyngs` || '';
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/app" element={<LoginPage />} />
          <Route path="/app/login" element={<LoginPage />} />
          <Route
            path="/app/Createnewpassword/:userid/:createpasswordKey"
            element={<Createnewpassword />}
          />
          <Route element={<SidebarAndProtected />}>
            <Route
              path="/app/workforce/demand/detail"
              element={
                <WorkforceDemandDetail modulename="Workforce" menu="Demand Details" />
              }
            />
            <Route
              path="/app/workforce/talent/detail"
              element={
                <WorkforceEmployeeDetails modulename="Workforce" menu="Talent Details" />
              }
            />
            <Route
              path="/app/workforce/demand"
              element={<WorkforceDemandList modulename="Workforce" menu="Demands" />}
            />
            <Route
              path="/app/workforce/talent"
              element={<WorkforceTalent modulename="Workforce" menu="Talent" />}
            />
            <Route
              path="/app/workforce/eprofile"
              element={<WorkforceEprofile modulename="Workforce" menu="E-Profile" />}
            />
            <Route
              path="/app/projects/planning"
              element={<ProjectPlanning modulename="Projects" menu="Planning" />}
            />
            <Route
              path="/app/projects/staffing"
              element={<ProjectStaffing modulename="Projects" menu="Staffing" />}
            />
            <Route
              path="/app/projects/timesheet"
              element={<ProjectTimesheet modulename="Projects" menu="Timesheet" />}
            />
            <Route
              path="/app/finance/finance"
              element={<AppFinancials modulename="Finance" menu="Finance" />}
            />
            <Route
              path="/app/projects"
              element={<ProjectListing modulename="Projects" menu="Projects" />}
            />
            <Route path="/app/subscriptions" element={<TenantLayout />} />
            <Route
              path="/app/admin/masterdata"
              element={<AdminApp modulename="Admin" menu="Master Data" />}
            />
            <Route
              path="/app/admin/useraccess"
              element={<AdminApp modulename="Admin" menu="User Access" />}
            />
            <Route
              path="/app/admin/configuration"
              element={<AdminApp modulename="Admin" menu="Configuration" />}
            />
            <Route path="/app/dashboard" element={<Analytics />} />
            <Route path="/app/reports" element={<ReportsApp />} />
            <Route
              path="/app/dashboards/projects"
              element={<Analytics modulename="Analytics" menu="Project Dashboards" />}
            />
            <Route
              path="/app/dashboards/finance"
              element={
                <AnalyticsForFinance
                  modulename="Analytics"
                  menu="Finance Dashboards"
                />
              }
            />
            <Route
              path="/app/dashboards/workforce"
              element={
                <WorkforceAnalytics
                  modulename="Analytics"
                  menu="Workforce Dashboards"
                />
              }
            />
            <Route
              path="/app/reports/workforce"
              element={<ReportsApp modulename="Reports" menu="Workforce Reports" />}
            />
            <Route
              path="/app/reports/projects"
              element={
                <TimesheetReportsApp
                  modulename="Reports"
                  menu="Project Reports"
                />
              }
            />
            <Route
              path="/app/reports/finance"
              element={
                <FinanceReportsApp
                  modulename="Reports"
                  menu="Finance Reports"
                />
              }
            />
            <Route
              path="/app/project/detail"
              element={<ProjectDetails modulename="Projects" menu="Project Details" />}
            />
            <Route path="/app/home" element={<Homedashboard />} />
            <Route path="/app/index.html" element={<Homedashboard />} />
            <Route path="/app/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AllRoutes;
