// *************** Analytics

export const ACTION_GET_NOTIFICATION_FOR_HOMEPAGE = 'GET_NOTIFICATION_FOR_HOMEPAGE'
export const ACTION_GET_METRICS_FOR_HOME = 'GET_METRICS_FOR_HOME'
export const ACTION_GET_EFFORT_HISTROY_METRICS = 'GET_EFFORT_HISTROY_METRICS'
export const ACTION_GET_REVENUE_FOR_DASHBOARD = 'GET_REVENUE_FOR_DASHBOARD'
export const ACTION_GET_PROFITABILITY_FOR_DASHBOARD = 'GET_PROFITABILITY_FOR_DASHBOARD'
export const ACTION_GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD = 'GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD'
export const ACTION_GET_PRIMARYSKILL_ALLOCATION_FOR_WORKFORCE_DASHBOARD = 'GET_PRIMARYSKILL_ALLOCATION_FOR_WORKFORCE_DASHBOARD'
export const ACTION_GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD = 'GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD'
export const ACTION_GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD = 'GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD'

// *************** Auth

export const ACTION_RESET_PASSWORD = 'RESET_PASSWORD'

// *************** Configuration

export const ACTION_GET_TIME_CATEGORY_MASTER = 'GET_TIME_CATEGORY_MASTER'
export const ACTION_GET_TEMPLATE_MASTER = 'GET_TEMPLATE_MASTER'
export const ACTION_GET_RULES_MASTER = 'GET_RULES_MASTER'
export const ACTION_GET_DEMAND_MATCH = 'GET_DEMAND_MATCH'
export const ACTION_GET_NOTIFICATION_MASTER = 'GET_NOTIFICATION_MASTER'
export const ACTION_GET_ROLES_MASTER = 'GET_ROLES_MASTER'
export const ACTION_GET_MENU_MASTER = 'GET_MENU_MASTER'
export const ACTION_GET_ROLE_BY_ID = 'GET_ROLE_BY_ID'

// **************** Master Data > Cuastomer

export const ACTION_GET_CUSTOMER_MASTERLIST = 'GET_CUSTOMER_MASTERLIST'
export const ACTION_GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID'
export const ACTION_GET_COMPANY_MASTER_FILTER = 'GET_COMPANY_MASTER_FILTER'
export const ACTION_GET_REGION_MASTER_FILTER = 'GET_REGION_MASTER_FILTER'
export const ACTION_GET_INDUSTRY_MASTER_FILTER = 'GET_INDUSTRY_MASTER_FILTER'
export const ACTION_GET_ACCOUNT_MASTER_FILTER = 'GET_ACCOUNT_MASTER_FILTER'
export const ACTION_GET_COUNTRY_MASTER_FILTER = 'GET_COUNTRY_MASTER_FILTER'
export const ACTION_GET_SOURCE_MASTER_FILTER = 'GET_SOURCE_MASTER_FILTER'
export const ACTION_GET_ALL_CONTACTS_OF_A_CUSTOMER = 'GET_ALL_CONTACTS_OF_A_CUSTOMER'
export const ACTION_GET_ALL_CONTACTS_OF_A_CUSTOMER_GROUP = 'GET_ALL_CONTACTS_OF_A_CUSTOMER_GROUP'
export const ACTION_GET_ALL_ROLES_OF_A_CUSTOMER = 'GET_ALL_ROLES_OF_A_CUSTOMER'
export const ACTION_GET_CUSTOMER_DOCUMENT_LIST = 'GET_CUSTOMER_DOCUMENT_LIST'
export const ACTION_GET_CUSTOMER_GROUP_DOCUMENT_LIST = 'GET_CUSTOMER_GROUP_DOCUMENT_LIST'
export const ACTION_GET_CUSTOMER_DOCUMENT_TYPE_LIST = 'GET_CUSTOMER_DOCUMENT_TYPE_LIST'

// **************** Master Data > Role
export const ACTION_GET_DELIVERY_ROLE_MASTER_BY_ID = 'GET_DELIVERY_ROLE_MASTER_BY_ID'

// **************** Master Data > Users
export const ACTION_GET_USER_TYPE_LIST = 'GET_USER_TYPE_LIST'
export const ACTION_GET_ROLE_MASTER = 'GET_ROLE_MASTER'
export const ACTION_GET_CUSTOMER_GROUP_MASTER = 'GET_CUSTOMER_GROUP_MASTER'
export const ACTION_GET_DELIVERY_ROLE_MASTER = 'GET_DELIVERY_ROLE_MASTER'
export const ACTION_GET_BUSINESS_MASTER = 'GET_BUSINESS_MASTER'
export const ACTION_GET_BUSINESSGROUP_MASTER = 'GET_BUSINESSGROUP_MASTER'
export const ACTION_GET_SERVICELINE_MASTER = 'GET_SERVICELINE_MASTER'
export const ACTION_GET_SERVICEGROUP_MASTER = 'GET_SERVICEGROUP_MASTER'
export const ACTION_GET_TECHNOLOGY_GROUP = 'GET_TECHNOLOGY_GROUP'
export const ACTION_GET_STATE_MASTER = 'GET_STATE_MASTER'
export const ACTION_GET_LOCATION_MASTER = 'GET_LOCATION_MASTER'
export const ACTION_GET_REGION_MASTER = 'GET_REGION_MASTER'
export const ACTION_GET_COUNTRY_MASTER = 'GET_COUNTRY_MASTER'
export const ACTION_GET_TASK_MASTER = 'GET_TASK_MASTER'
export const ACTION_GET_TASK_GROUPS_MASTER = 'GET_TASK_GROUPS_MASTER'
export const ACTION_GET_GENDER_MASTER = 'GET_GENDER_MASTER'
export const ACTION_GET_TIME_TYPE = 'GET_TIME_TYPE'
export const ACTION_GET_NATIONALITY_MASTER = 'GET_NATIONALITY_MASTER'
export const ACTION_GET_OVERHEADS = 'GET_OVERHEADS'
export const ACTION_GET_PRODUCT_MASTERLIST = 'GET_PRODUCT_MASTERLIST'
export const ACTION_GET_LIST_OF_STATUS_BY_E = 'GET_LIST_OF_STATUS_BY_E'
export const ACTION_GET_COMPANY_HOLIDAYS_MASTERLIST = 'GET_COMPANY_HOLIDAYS_MASTERLIST'
export const ACTION_GET_CUSTOMER_HOLIDAYS_MASTERLIST = 'GET_CUSTOMER_HOLIDAYS_MASTERLIST'
export const ACTION_GET_USER_ACCESS_LIST = 'GET_USER_ACCESS_LIST'
export const ACTION_GET_DM_LIST = 'GET_DM_LIST'
export const ACTION_GET_PMO_LIST = 'GET_PMO_LIST'
export const ACTION_GET_COST_RATES = 'GET_COST_RATES'
export const ACTION_GET_EXCHANGE_RATE_MASTER = 'GET_EXCHANGE_RATE_MASTER'
export const ACTION_GET_COMPANY_WEEKDAYS = 'GET_COMPANY_WEEKDAYS'
export const ACTION_GET_WEEKDAYS_OF_A_CUSTOMER = 'GET_WEEKDAYS_OF_A_CUSTOMER'
export const ACTION_GET_ANNOUNCEMENT_MASTER = 'GET_ANNOUNCEMENT_MASTER'
export const ACTION_GET_COURSE_LIST_MASTER = 'GET_COURSE_LIST_MASTER'
export const ACTION_GET_EDUCATION_LIST_MASTER = 'GET_EDUCATION_LIST_MASTER'
export const ACTION_GET_SPEC_LIST_MASTER = 'GET_SPEC_LIST_MASTER'
export const ACTION_GET_WORKERTYPE_LIST_MASTER = 'GET_WORKERTYPE_LIST_MASTER'
export const ACTION_GET_LEAVETYPE_LIST_MASTER = 'GET_LEAVETYPE_LIST_MASTER'
export const ACTION_GET_INDUSTRY_LIST_MASTER = 'GET_INDUSTRY_LIST_MASTER'

// ***************** Projects

export const ACTION_GET_PROJECTS = 'GET_PROJECTS'
export const ACTION_GET_PROJECTS_BY_USER = 'GET_PROJECTS_BY_USER'
export const ACTION_GET_PROJECTS_BY_ID = 'GET_PROJECTS_BY_ID'
export const ACTION_CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT'
export const ACTION_GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const ACTION_GET_LIST_TYPE_OF_PROJECT = 'GET_LIST_TYPE_OF_PROJECT'
export const ACTION_GET_LIST_COMPANY = 'GET_LIST_COMPANY'
export const ACTION_GET_PROJECT_LIST_BILLING_TYPE = 'GET_PROJECT_LIST_BILLING_TYPE'
export const ACTION_GET_LIST_OF_STATUS = 'GET_LIST_OF_STATUS'
export const ACTION_GET_SERVICE_LINE_MASTER_LIST = 'GET_SERVICE_LINE_MASTER_LIST'
export const ACTION_GET_BUSINESS_UNIT_MASTER_LIST = 'GET_BUSINESS_UNIT_MASTER_LIST'
export const ACTION_GET_CURRENCY_MASTER_LIST = 'GET_CURRENCY_MASTER_LIST'
export const ACTION_GET_OPPORTUNITY_LIST = 'GET_OPPORTUNITY_LIST'
export const ACTION_GET_TECHNOLOGY_LIST = 'GET_TECHNOLOGY_LIST'
export const ACTION_GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const ACTION_GET_USER_MASTER_LIST = 'GET_USER_MASTER_LIST'
export const ACTION_SAVE_PROJECT_DOCUMENT = 'SAVE_PROJECT_DOCUMENT'
export const ACTION_GET_PROJECT_DOCUMENT_LIST = 'GET_PROJECT_DOCUMENT_LIST'
export const ACTION_DELETE_PROJECT_DOCUMENT = 'DELETE_PROJECT_DOCUMENT'
export const ACTION_DOWNLOAD_PROJECT_DOCUMENT = 'DOWNLOAD_PROJECT_DOCUMENT'
export const ACTION_GET_PROJECT_DOCUMENT_TYPE_LIST = 'GET_PROJECT_DOCUMENT_TYPE_LIST'
export const ACTION_GET_BUDGET_ESTIMATION = 'GET_BUDGET_ESTIMATION'
export const ACTION_GET_PROJECT_DETAIL_TIMELINE = 'GET_PROJECT_DETAIL_TIMELINE'

// *********** Project > Staffing

export const ACTION_GET_PROJECT_STAFFING_LIST = 'GET_PROJECT_STAFFING_LIST'
export const ACTION_GET_PROJECT_STAFFING_LIST_SEARCH = 'GET_PROJECT_STAFFING_LIST_SEARCH'
export const ACTION_INSERT_PROJECT_STAFFING = 'INSERT_PROJECT_STAFFING'
export const ACTION_GET_STAFF_STATUS_TYPE_S = 'GET_STAFF_STATUS_TYPE_S'
export const ACTION_SYNC_STAFFING_DATA = 'SYNC_STAFFING_DATA'

// *********** Project > timesheet
export const ACTION_GET_TASK_BY_PROJECTID_TIMESHEET = 'GET_TASK_BY_PROJECTID_TIMESHEET'
export const ACTION_GET_TASK_TIMESHEET_CONFIG = 'GET_TASK_TIMESHEET_CONFIG'
export const ACTION_GET_TIMESHEET_PROJECTS_E = 'GET_TIMESHEET_PROJECTS_E'
export const ACTION_GET_TIMESHEET_PROJECTS_M = 'GET_TIMESHEET_PROJECTS_M'
export const ACTION_GET_TIMESHEET_USERLIST = 'GET_TIMESHEET_USERLIST'
export const ACTION_GET_TIMESHEET_TASKS = 'GET_TIMESHEET_TASKS'
export const ACTION_GET_TIMESHEET_TASK_TYPE = 'GET_TIMESHEET_TASK_TYPE'
export const ACTION_STAFFING_SUMMARY_BY_PROJECTID = 'STAFFING_SUMMARY_BY_PROJECTID'
export const ACTION_GET_TIMESHEET_ORG_TASKS_BY_PRO_ID =
    'GET_TIMESHEET_ORG_TASKS_BY_PRO_ID'
export const ACTION_GET_TIMESHEET_TASKS_BY_PRO_ID = 'GET_TIMESHEET_TASKS_BY_PRO_ID'
export const ACTION_SAVE_TIMESHEET_AS_DRAFT = 'SAVE_TIMESHEET_AS_DRAFT'
export const ACTION_SUBMIT_TIMESHEET_OF_EMPLOYEE = 'SUBMIT_TIMESHEET_OF_EMPLOYEE'
export const ACTION_CHANGE_STATUS_OF_TIMESHEET = 'CHANGE_STATUS_OF_TIMESHEET'
export const ACTION_GET_TIMESHEET_REPORT_STARTDATE_ENDDATE =
    'GET_TIMESHEET_REPORT_STARTDATE_ENDDATE'
export const ACTION_GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT =
    'GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT'
export const ACTION_GET_MANAGER_TIMESHEET_RECORDS = 'GET_MANAGER_TIMESHEET_RECORDS'

// *********** Project > planning

export const ACTION_GET_ACTIVITY_BY_PROJECT = 'GET_ACTIVITY_BY_PROJECT'
export const ACTION_GET_SEARCH_ACTIVITY_TASK_BY_ID = 'GET_SEARCH_ACTIVITY_TASK_BY_ID'
export const ACTION_GET_TASK_GROUPS = 'GET_TASK_GROUPS'
export const ACTION_GET_TASK_BY_GROUP_ID = 'GET_TASK_BY_GROUP_ID'
export const ACTION_SAVE_ACTIVITY_PLANNING = 'SAVE_ACTIVITY_PLANNING'
export const ACTION_GET_LIST_OF_PROJECT_ROLE = 'GET_LIST_OF_PROJECT_ROLE'
export const ACTION_SAVE_PROJECT_ROLE = 'SAVE_PROJECT_ROLE'
export const ACTION_GET_CUSTOMER_ROLE_BY_ID = 'GET_CUSTOMER_ROLE_BY_ID'
export const ACTION_GET_MILESTONE_OF_PROJECT = 'GET_MILESTONE_OF_PROJECT'
export const ACTION_SAVE_MILESTONE_PROJECT_ID = 'SAVE_MILESTONE_PROJECT_ID'
export const ACTION_GET_BUDGET_OF_PROJECT = 'GET_BUDGET_OF_PROJECT'
export const ACTION_SAVE_BUDGET_OF_PROJECT = 'SAVE_BUDGET_OF_PROJECT'
export const ACTION_SAVE_BUDGET_ESTIMATION = 'SAVE_BUDGET_ESTIMATION'

// *********** Project > financials

export const ACTION_GET_OVERHEAD_MASTER = 'GET_OVERHEAD_MASTER'
export const ACTION_GET_TIMESHEET_BILLING_INPUT_SUMMARY = 'GET_TIMESHEET_BILLING_INPUT_SUMMARY'
export const ACTION_GET_TIMESHEET_BILLING = 'GET_TIMESHEET_BILLING'
export const ACTION_GET_PROJECT_EXPENSES = 'GET_PROJECT_EXPENSES'
export const ACTION_INSERT_PROJECT_EXPENSES = 'INSERT_PROJECT_EXPENSES'
export const ACTION_INSERT_PROJECT_REVENUE = 'INSERT_PROJECT_REVENUE'
export const ACTION_GET_PROJECT_REVENUE = 'GET_PROJECT_REVENUE'
export const ACTION_INSERT_PROJECT_USERPAYROLL = 'INSERT_PROJECT_USERPAYROLL'
export const ACTION_GET_PROJECT_USER_PAYROLL = 'GET_PROJECT_USER_PAYROLL'
export const ACTION_IMPORT_PROJECT_EXPENSES = 'IMPORT_PROJECT_EXPENSES'
export const ACTION_IMPORT_PROJECT_REVENUE = 'IMPORT_PROJECT_REVENUE'
export const ACTION_IMPORT_PAYROLL_USER = 'IMPORT_PAYROLL_USER'
export const ACTION_PROCESS_PROFITABLE = 'PROCESS_PROFITABLE'
export const ACTION_GET_PROJECT_PROFITABLE = 'GET_PROJECT_PROFITABLE'
export const ACTION_GET_PROJECT_EXPENSE_TEMPLATE = 'GET_PROJECT_EXPENSE_TEMPLATE'
export const ACTION_GET_PROJECT_REVENUE_TEMPLATE = 'GET_PROJECT_REVENUE_TEMPLATE'
export const ACTION_GET_USER_LEAVE_DATA = 'GET_USER_LEAVE_DATA'
export const ACTION_GET_LEAVETYPE_MASTER = 'GET_LEAVETYPE_MASTER'

// *********** Report

export const ACTION_GET_REPORT_MASTER_LIST = 'GET_REPORT_MASTER_LIST'
export const ACTION_GET_TIMESHEET_REPORT_FOR_EMPLOYEE =
    'GET_TIMESHEET_REPORT_FOR_EMPLOYEE'
export const ACTION_GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE =
    'GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE'
export const ACTION_GET_TIMESHEET_REPORT_FOR_EXCEPTION =
    'GET_TIMESHEET_REPORT_FOR_EXCEPTION'
export const ACTION_GET_FINANCE_REPORT = 'GET_FINANCE_REPORT'
export const ACTION_GET_CAPACITY_REPORT = 'GET_CAPACITY_REPORT'
export const ACTION_GET_RELEASE_PLAN_REPORT = 'GET_RELEASE_PLAN_REPORT'
export const ACTION_GET_PDP_AGING_REPORT = 'GET_PDP_AGING_REPORT'
export const ACTION_GET_STAFFING_DETAILS_REPORT = 'GET_STAFFING_DETAILS_REPORT'
export const ACTION_GET_UTILIZATION_REPORT_FOR_EMPLOYEE =
    'GET_UTILIZATION_REPORT_FOR_EMPLOYEE'
export const ACTION_GET_PROJECT_WISE_UTILIZATION_REPORT =
    'GET_PROJECT_WISE_UTILIZATION_REPORT'
export const ACTION_GET_EXIT_PIPELINE_REPORT = 'GET_EXIT_PIPELINE_REPORT'
export const ACTION_GET_DEMAND_SUMMARY_REPORT = 'GET_DEMAND_SUMMARY_REPORT'
export const ACTION_GET_TIMESHEET_TRACKING_REPORT = 'GET_TIMESHEET_TRACKING_REPORT'

// *********** subscription

export const ACTION_GET_TENANTS_MASTER = 'GET_TENANTS_MASTER'
export const ACTION_GET_SUBSCRIPTIONS_MASTER = 'GET_SUBSCRIPTIONS_MASTER'
export const ACTION_GET_TENANT_SUBSCRIPTION_PRICING_BY_ID =
    'GET_TENANT_SUBSCRIPTION_PRICING_BY_ID'

// *********** Workforce Demand

export const ACTION_GET_WORK_MODE_LIST = 'GET_WORK_MODE_LIST'
export const ACTION_GET_DEMAND_LIST_MASTER = 'GET_DEMAND_LIST_MASTER'
export const ACTION_SAVE_DEMAND_STATUS_MASTER = 'SAVE_DEMAND_STATUS_MASTER'
export const ACTION_GET_DEMAND_MASTER = 'GET_DEMAND_MASTER'
export const ACTION_SAVE_DEMAND_MATCH = 'SAVE_DEMAND_MATCH'
export const ACTION_SAVE_DEMAND_LIST_MASTER = 'SAVE_DEMAND_LIST_MASTER'
export const ACTION_SAVE_DEMAND_SKILL_MASTER = 'SAVE_DEMAND_SKILL_MASTER'
export const ACTION_SAVE_DEMAND_ROLE_MASTER = 'SAVE_DEMAND_ROLE_MASTER'
export const ACTION_GET_SKILL_LIST_MASTER = 'GET_SKILL_LIST_MASTER'
export const ACTION_GET_DEMAND_TECHNOLOGY_MASTER = 'GET_DEMAND_TECHNOLOGY_MASTER'
export const ACTION_GET_INSUDTRY_MASTER = 'GET_INSUDTRY_MASTER'
export const ACTION_GET_DEMAND_SKILLAREA_MASTER = 'GET_DEMAND_SKILLAREA_MASTER'
export const ACTION_GET_DEMAND_SKILL_MASTER = 'GET_DEMAND_SKILL_MASTER'
export const ACTION_GET_DEMAND_ROLE_MASTER = 'GET_DEMAND_ROLE_MASTER'
export const ACTION_GET_STAFF_STATUS_TYPE_M = 'GET_STAFF_STATUS_TYPE_M'
export const ACTION_GET_TECHNOLOGY_MASTER = 'GET_TECHNOLOGY_MASTER'
export const ACTION_GET_SKILLAREA_MASTER = 'GET_SKILLAREA_MASTER'
export const ACTION_GET_DEMAND_MATCH_DETAILS = 'GET_DEMAND_MATCH_DETAILS'
export const ACTION_GET_DEMAND_LIST_FOR_TALENT = 'GET_DEMAND_LIST_FOR_TALENT'

// **************** EPROFILE
export const ACTION_GET_EDUCATIONBACKGROUND_LIST = 'GET_EDUCATIONBACKGROUND_LIST'
export const ACTION_SAVE_USEREDUCATION = 'SAVE_USEREDUCATION'
export const ACTION_GET_EDUCATION_MASTER = 'GET_EDUCATION_MASTER'
export const ACTION_GET_COURSE_MASTER = 'GET_COURSE_MASTER'
export const ACTION_GET_SPECIALISATION_MASTER = 'GET_SPECIALISATION_MASTER'
export const ACTION_GET_PROJECTS_EPROFILE = 'GET_PROJECTS_EPROFILE'
export const ACTION_GET_EMPLOYEE_SUMMARY = 'GET_EMPLOYEE_SUMMARY'
export const ACTION_GET_ALLOCATION_STATUS = 'GET_ALLOCATION_STATUS'
export const ACTION_GET_SKILL_MASTER = 'GET_SKILL_MASTER'
export const ACTION_GET_LEVEL_MASTER = 'GET_LEVEL_MASTER'
export const ACTION_GET_PREVIOUS_EXPERIENCE = 'GET_PREVIOUS_EXPERIENCE'
export const ACTION_GET_CERTIFICATIONS = 'GET_CERTIFICATIONS'
export const ACTION_GET_SKILLS_EMPLOYEE = 'GET_SKILLS_EMPLOYEE'
export const ACTION_GET_EMPLOYEE_DOCUMENT_LIST = 'GET_EMPLOYEE_DOCUMENT_LIST'
export const ACTION_GET_EMPLOYEE_DOCUMENT_TYPE_LIST = 'GET_EMPLOYEE_DOCUMENT_TYPE_LIST'

// ************* Talent

export const ACTION_GET_DEPARTMENT_MASTER = 'GET_DEPARTMENT_MASTER'
export const ACTION_GET_DESIGNATION_MASTER = 'GET_DESIGNATION_MASTER'
export const ACTION_GET_BAND_MASTER = 'GET_BAND_MASTER'
export const ACTION_GET_CITY_MASTER = 'GET_CITY_MASTER'
export const ACTION_GET_WORKERTYPE_MASTER = 'GET_WORKERTYPE_MASTER'
export const ACTION_GET_WORKFORCEGROUP_MASTER = 'GET_WORKFORCEGROUP_MASTER'
export const ACTION_GET_COMPANY_MASTER = 'GET_COMPANY_MASTER'
export const ACTION_GET_EMPLOYEE_DETAILS = 'GET_EMPLOYEE_DETAILS'
export const ACTION_GET_ALLOCATION_STATUS_FOR_EMPLOYEE =
    'GET_ALLOCATION_STATUS_FOR_EMPLOYEE'
export const ACTION_GET_STAFF_STATUS_TYPE_W = 'GET_STAFF_STATUS_TYPE_W'
